.pageOmnie{
    padding-top: 3rem;
    text-align: center;
    &__row{
        align-items: center;
        &__img{
            img {
                max-height: 400px;
                width: auto;
                padding: 2rem 0;
            }
        }
        &__content{        
            @include rwd(laptop){
                padding: 0;
            }
        }
    }
    &__tytul{
        text-align: left;
        font-family: $fSecond;
        font-weight: 500;
    }
    &__text{        
        text-align: left;
        font-size: 18px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        h2{
            font-size: 20px;
            margin-bottom: 1rem;
            font-family: $fSecond;
            font-weight: 500;
        }
        h4{
            font-size: 20px;
            font-family: $fSecond;
            font-weight: 500;
        }
    }
    &__secondText {        
        background-color: transparent linear-gradient(90deg, #F5AFC4 0%, transparent 100%) 0% 0% no-repeat padding-box;
        background-image: url(http://localhost/lukoshek/wp-content/uploads/2022/04/kwiatki-01.png),
        -moz-linear-gradient(to right, #F5AFC4 0%, #fff 100%);
        background-repeat: no-repeat;
        background-position: right;
        text-align: left;
        padding: 3rem 0;
        li{
            margin: 1.5rem 0;
        }
    }
    &__certyficate{
        background-color: #DADBD9;
        padding: 3rem 0;
        margin: 2px 0 3rem 0;
        .row{
            align-items: center;
            text-align: left;
        }
        &__img{
            text-align: center;
            @include rwd(laptop){
                margin-bottom: 2rem;
            }
        }
        &__text{
            @include rwd(laptop){
                padding: 0;
            }
        }
    }
}