.pageCennik{
    &__text{
        text-align: left;
        margin-top: 3rem;
        margin-bottom: 3rem;
        &__title {
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 1rem;
            text-align: center;
        }
        &__text{
            text-align: left;
            font-size: 20px;  
            @include rwd(laptop){
                font-size: 16px;
            }
            font-weight: 300;
            p{
                margin-bottom: 1rem;
            }
            h2, h3{
                font-size: 20px;
                font-weight: 300;
            }
            h4{
                font-size: 18px;
            }
            &__table{
                width: 100%;
            }
            &__TDleft {
                width:90%;
                @include rwd(tablet){
                    width:65%;
                }
            }
            &__TDright {
                width:10%;
                @include rwd(tablet){
                    width:35%;
                }
            }
        }
    }
}