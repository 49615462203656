.MainSlider {
    margin-top: 0rem;
    margin-bottom: 3rem;
    text-align: center;
    background-color: transparent linear-gradient(90deg, #F5AFC4 0%, transparent 100%) 0% 0% no-repeat padding-box;
    background-image: url(http://localhost/lukoshek/wp-content/uploads/2022/04/kwiatki-01.png),
    -moz-linear-gradient(to right, #F5AFC4 0%, #fff 100%);
    background-repeat: no-repeat;
    background-position: right;
    &__row{
        align-items: center;
        padding: 2rem 0;
        &__img{
            img {
                max-height: 400px;
                width: auto;
                padding: 2rem 0;
            }
        }
    }
    &__tytul{
        font-family: $fSecond;
        text-align: left;
        font-size: 44px;
        font-weight: 500;
    }
    &__text{        
        text-align: left;
        font-size: 18px;
        margin-top: 1rem;
        margin-bottom: 2rem;
        h4{
            font-size: 20px;
            font-family: $fFirst;
        }
    }
    &__button{        
        text-align: left;
        @include rwd(laptop){
            text-align: center;
        }
        button{
            @include ButtonWhite;
        }
    }    
}