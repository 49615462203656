.Oferta {    
    margin-top: 0rem;
    margin-bottom: 0;
    @include rwd(tablet,true) {
        margin-bottom: 3rem;
    }
    text-align: center;
    &__title{        
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        margin-top: 1rem;
        @include rwd(tablet,true) {
            margin-top: 3rem;
        }
        margin-bottom: 1rem;
        h2{
        }
    }
    &__text{        
        text-align: center;
        margin-top: 1rem;
        margin-bottom: 3rem;
        p{
            font-size: 20px;
            font-weight: 300;
        }
    }
    &__3panels{
        @include rwd(tablet) {
            margin-bottom: 3rem;
        }
        &__icon{
            text-align: center;
            margin-bottom: 1rem;
            img {
                height: 95px;
                width: auto;
            }
        }
        &__tytul{
            text-align: center;
            font-size: 24px;
            text-transform: uppercase;
            font-weight: 300;
            span {
                display: block;
                margin: 1rem auto;
                width: 43px;
                height: 3px;
                border-top: 3px solid #F5AFC4;
                opacity: 1;
            }
        }
        &__text{        
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
            min-height: 100px;
            p{
                font-size: 16px;
                font-weight: 300;
            }
        }
        &__button{        
            text-align: center;
            margin: 1rem auto;
            button{
                @include ButtonWhite;
            }
        }
    }
}

.pageOferta{
    padding-top: 3rem;
    margin-bottom: 2rem;
    &__titlePage{
        margin-bottom: 2rem;
        text-align: center;
        text-transform: uppercase;
    }
    &__contentPage{

    }
    &__content{        
        @include rwd(laptop){
            padding: 0;
        }
    }
}