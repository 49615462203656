


.navbar-collapse{
    padding:10px 0;
    @include rwd(laptop) {
        ul li{
            text-align: left;
        }
        padding:0;
    }

    .nav-item{
        
        @include rwd(laptop,true) {
            padding: 0 10px;
            &::after{
                content: '.';
                position: relative;
                left: 10px;
                top: -5px;
                font-size: 1rem;
            }
            &:nth-child(5) {
                padding: 0 0 0 10px;
                margin-right: 20px;
                &::after{
                    content: '';
                }
            }
        }
        
    }

    .dropdown{      
        &:hover{
            -webkit-animation-name: unset;
            animation-name: unset;
            -webkit-animation-duration: unset;
            animation-duration: unset;
            -webkit-animation-timing-function: unset;
            animation-timing-function: unset;
            -webkit-animation-iteration-count: unset;
            animation-iteration-count: unset;
            
            @media(min-width:992px){
                .dropdown-menu{
                    display: block !important;   
                }
            }
        }
        .dropdown-menu{
            background-color:transparent;  
            border: 0;
            min-width: 250px;
            margin-top:-5px; 
            display:none; 
            @include rwd(laptop){
                display: flex;
                flex-direction: column;
            } 
            background-color: rgba(255, 255, 255, 0.86); 
            @include rwd(laptop,true){
                box-shadow: 3px 3px 3px #A5A5A5BF;
            }
            
            //max
            @media(max-width:992px){
                padding-top: 0;
                padding-left: 7px;
            }

            .nav-link{             
                transition:.2s;

                @include rwd(laptop){
                    padding:11px 7px 9px 20px;   
                }

                &.hvr-border-fade{
                    border-bottom: 1px solid transparent;

                    &:hover{
                        border-bottom: 1px solid ;
                        border-bottom:1px solid #F5AFC4;
                        box-shadow: none !important;
                    }
                }
            }
        }
    }
}