.map{
    iframe{
        width:100%;
        height: 400px;
        @include rwd(tablet) {
            max-height: 60vh;
        }
    }
    &__text{
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
        &__title {
            @include rwd(tablet) {
                margin-bottom: 1rem;
            }
            h3{
                font-size: 30px;
                font-weight: 300;
            }        
        }
        &__text{
            text-align: center;
            font-size: 20px;
            font-weight: 300;
        }
    }
}
