.FooterText{
    text-align: justify;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 300;
    background-color: #DADBD9;
    color: #464848;
    &__SmallBg{
        &__left{
            background: url('../images/kwiatek-lewy.png');
            background-repeat: no-repeat;
            background-position: top;
            background-size: 100px 100px;
        }
        &__right{
            background: url('../images/kwiatek-prawy.png');
            background-repeat: no-repeat;
            background-position: center bottom;
            background-size: 100px 100px;
        }
    }
    &__text{        
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
}