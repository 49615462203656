@charset "UTF-8";
html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ol, ul {
  list-style: none;
}

img {
  max-width: 100%;
  height: auto;
}

/* przykład 
##SCSS 
div {
  @include css3-prefix(transform, scale3d(2.5, 2, 1.5));
}
 ##CSS 
div {
  -webkit-transform: scale3d(2.5, 2, 1.5);
  -moz-transform: scale3d(2.5, 2, 1.5);
  -ms-transform: scale3d(2.5, 2, 1.5);
  -o-transform: scale3d(2.5, 2, 1.5);
  transform: scale3d(2.5, 2, 1.5);
}
*/
/* Vertical Centering SCSS */
/*
## SCSS 
div {
  @include vertical-center();
}
## CSS 
div {
  position: relative;
  top: 50%;
  -ms-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
*/
/* Retina-Ready Image SCSS */
/* 
###SCSS
.image {
  background: url("my-image.png") no-repeat;
  @include retina-image("my-image2x.png", 1000px, 500px);
}
###CSS
.image {
  background: url("my-image.png") no-repeat;
}
@media (min--moz-device-pixel-ratio: 1.3), 
(-o-min-device-pixel-ratio: 2.6 / 2), 
(-webkit-min-device-pixel-ratio: 1.3), 
(min-device-pixel-ratio: 1.3), 
(min-resolution: 1.3dppx) {
  .image {
    background-image: url("my-image2x.png");
    background-size: 1000px 500px;
  }
}
 */
/* Example of use is at the bottom of file */
/* Example of use is at the bottom of file */
/*

  Sample file name:
    • OpenSans-Bold.ttf
    • [prefix][font_weight].ttf

  Mixin args:
    • path with prefix of files
    • font family
    • fonts weights: 
      • name (font_weight in file names)
      • weight
      • style

  Example:
    $Roboto : (
      (
        'name'   : 'Regular',
        'weight' : 400,
        'style'  : 'normal'
      ),
      (
        'name'   : 'Medium',
        'weight' : 500,
        'style'  : 'normal'
      ),
      (
        'name'   : 'Bold',
        'weight' : 700,
        'style'  : 'normal'
      )
    );
    
    @include fontFace('../sources/fonts/Roboto/Roboto-', 'Roboto', $Roboto);

*/
/*
  Example:

    ul {
      margin-bottom: 15px;

      @include rwd(tablet, true) {
        margin-bottom: 30px;
      }
      
      li {
        margin-bottom: 20px;

        @include rwd(tablet) {
          margin-bottom: 10px;
        }
      }
    }

  Result:

    ul {
      margin-bottom: 15px;
    }

    ul li {
      margin-bottom: 20px;
    }

    @media (min-width: 769px) {
      ul {
        margin-bottom: 30px;
      }
    }

    @media (max-width: 768px) {
      ul li {
        margin-bottom: 10px;
      }
    }

*/
body {
  color: #626363;
  font-family: "museo300";
  font-size: 16px;
  font-weight: 300;
}

.mainContainer {
  max-width: 1410px;
  margin: 0 auto;
}

@media (max-width: 768px) {
  .mainContainer {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media (min-width: 769px) {
  .mainContainer {
    padding-right: unset;
    padding-left: unset;
  }
}

.container-fluid {
  width: 100%;
}

@media (min-width: 1025px) {
  .container-fluid {
    padding-right: unset;
    padding-left: unset;
  }
}

@media (max-width: 768px) {
  .container-fluid {
    padding-right: unset;
    padding-left: unset;
  }
}

a {
  color: #626363;
}

a:hover {
  color: #F5AFC4;
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

@keyframes hvr-pulse {
  25% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  75% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
  }
}

/* BACKGROUND */
/* COLORS */
/*  others */
p {
  font-size: 16px;
  font-weight: 300;
}

.aLeft {
  text-align: left;
}

.aRight {
  text-align: right;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #890b01;
}

.slide {
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 480px) {
  .psuedo-background-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.novisible {
  display: none !important;
}

.showvisible {
  display: block !important;
  padding: 0 !important;
}

.centerOfScreen {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  /* Yep! */
  width: 48%;
  height: 59%;
}

h1, h2, h3, h4, h5 {
  font-family: "museo300";
}

h1 {
  font-size: 30px;
}

.mainContainer ul,
.mainContainer ol {
  list-style: disc;
  margin-left: 1rem;
}

@media (max-width: 768px) {
  .mainContainer ul,
  .mainContainer ol {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

#SectionBG {
  background-image: url(), -moz-linear-gradient(to right, #F5AFC4 0%, #fff 100%);
  background-repeat: no-repeat;
  background-position: right;
  height: 4px;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'museo_sans_500regular';
  src: url("../fonts/MuseoSans_500-webfont.eot");
  src: url("../fonts/MuseoSans_500-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/MuseoSans_500-webfont.woff") format("woff"), url("../fonts/MuseoSans_500-webfont.ttf") format("truetype"), url("../fonts/MuseoSans_500-webfont.svg#museo_sans_500regular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo500';
  src: url("../fonts/museo500-regular-webfont.eot");
  src: url("../fonts/museo500-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/museo500-regular-webfont.woff") format("woff"), url("../fonts/museo500-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'museo300';
  src: url("../fonts/museo300-regular-webfont.eot");
  src: url("../fonts/museo300-regular-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/museo300-regular-webfont.woff") format("woff"), url("../fonts/museo300-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.burger {
  width: 45px;
  height: 30px;
  display: inline-block;
  margin-right: 10px;
  transition: .3s;
  margin-top: -11px;
  background-color: transparent;
  border: 0;
}

.burger:hover, .burger:active, .burger:focus {
  outline: 0;
}

@media (min-width: 992px) {
  .burger {
    display: none;
  }
}

.burger span {
  display: block;
  width: 25px;
  height: 2px;
  background-color: #F5AFC4;
  margin-top: 6px;
  transition: .3s;
}

.burger span:nth-child(2) {
  transition: .1s !important;
}

.burger.active {
  /* margin-top:-20px; */
  margin-bottom: 2rem;
}

.burger.active span:nth-child(1) {
  transform: rotate(45deg);
  margin-top: 13px;
}

.burger.active span:nth-child(2) {
  opacity: 0;
}

.burger.active span:nth-child(3) {
  transform: rotate(-45deg);
  margin-top: -10px;
}

#cookie-msg {
  padding: 12px !important;
  background-color: #000000;
  z-index: 100;
}

#cookie-msg .msg {
  font-size: 12px;
}

@media (min-width: 576px) {
  #cookie-msg .msg {
    font-size: 14px;
  }
}

#cookie-msg a {
  color: #626363 !important;
}

#cookie-msg .btn-aceptar {
  background-color: #000000;
  margin-left: 13px;
  font-size: 13px !important;
  color: #626363 !important;
  margin: 2px;
  display: inline-block;
}

.pageCennik__text {
  text-align: left;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pageCennik__text__title {
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
  text-align: center;
}

.pageCennik__text__text {
  text-align: left;
  font-size: 20px;
  font-weight: 300;
}

@media (max-width: 1280px) {
  .pageCennik__text__text {
    font-size: 16px;
  }
}

.pageCennik__text__text p {
  margin-bottom: 1rem;
}

.pageCennik__text__text h2, .pageCennik__text__text h3 {
  font-size: 20px;
  font-weight: 300;
}

.pageCennik__text__text h4 {
  font-size: 18px;
}

.pageCennik__text__text__table {
  width: 100%;
}

.pageCennik__text__text__TDleft {
  width: 90%;
}

@media (max-width: 768px) {
  .pageCennik__text__text__TDleft {
    width: 65%;
  }
}

.pageCennik__text__text__TDright {
  width: 10%;
}

@media (max-width: 768px) {
  .pageCennik__text__text__TDright {
    width: 35%;
  }
}

.FooterText {
  text-align: justify;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 300;
  background-color: #DADBD9;
  color: #464848;
}

.FooterText__SmallBg__left {
  background: url("../images/kwiatek-lewy.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100px 100px;
}

.FooterText__SmallBg__right {
  background: url("../images/kwiatek-prawy.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 100px 100px;
}

.FooterText__text {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.footer {
  background-color: #8b8b8b;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.footer__icon {
  text-align: center;
}

.footer__icon img {
  text-align: center;
  height: 50px;
  width: auto;
}

@media (max-width: 1280px) {
  .footer__icon img {
    height: 30px;
  }
}

.footer__text {
  font-size: 18px;
  text-align: center;
  color: #fff;
  font-weight: 300;
}

.footer__text a {
  color: #fff;
}

.footer .col-12 {
  margin-bottom: 1rem;
}

.AfterFooter {
  background-color: #fff;
}

.AfterFooter__copyBox {
  color: #626363;
  padding: 10px 0;
  font-size: 12px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .AfterFooter__copyBox {
    font-size: 10px;
  }
}

.AfterFooter__copyBox__Model {
  text-align: left;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Model {
    text-align: center;
  }
}

.AfterFooter__copyBox__Anronet {
  text-align: right;
}

@media (max-width: 600px) {
  .AfterFooter__copyBox__Anronet {
    text-align: center;
  }
}

.header__menu ul {
  list-style: none;
}

.header__menu ul li {
  font-family: "museo_sans_500regular";
  font-size: 14px;
  text-transform: uppercase;
}

@media (max-width: 768px) {
  .header__menu ul li {
    margin: .2rem 0;
    padding-left: .4rem;
  }
}

.header__icoPhone {
  margin: 0;
  background: #F5AFC4 0% 0% no-repeat padding-box;
  border: 2px solid #F5AFC4;
  border-radius: 11px;
  padding: 5px 20px;
}

.header__icoPhone a.ico {
  font-family: "museo_sans_500regular";
  font-size: 14px;
  color: #fff;
}

.header__icoPhone a.ico:hover {
  text-decoration: none;
  color: #F5AFC4;
}

.header__icoPhone:hover {
  background: #FFF;
  border: 2px solid #F5AFC4;
}

.header__icoPhone:hover a {
  color: #F5AFC4;
}

.header__icoPhone.nofixed {
  display: block;
}

.header__icoPhone.fixed {
  display: none;
}

.header__logo {
  width: auto;
  height: 100px;
}

@media (max-width: 768px) {
  .header__logo {
    width: 160px;
    height: auto;
  }
}

@media (max-width: 600px) {
  .header__logo {
    width: 150px;
    height: auto;
  }
}

@media (max-width: 480px) {
  .header__logo {
    width: 120px;
    height: auto;
  }
}

@media (max-width: 360px) {
  .header__logo {
    width: 100px;
    height: auto;
  }
}

.header #navbarNavDropdown {
  justify-content: center;
  flex-direction: row-reverse;
}

.header--fixed {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: #fff;
  width: 100%;
  max-width: unset;
  padding-bottom: 10px;
}

.header--fixed #navbarNavDropdown {
  flex-direction: unset;
}

.header--fixed .header__logo {
  height: 35px;
  width: auto;
}

.header--fixed .header__icoPhone.nofixed {
  display: none;
}

@media (max-width: 768px) {
  .header--fixed .header__icoPhone.nofixed {
    display: none !important;
  }
}

.header--fixed .header__icoPhone.fixed {
  display: block;
}

.header--fixed .header__icoPhone {
  margin: 0;
}

.header--fixed .header__icoPhone a.ico {
  font-size: 12px;
  font-weight: 500;
}

.header--fixed .header__icoPhone img.ico {
  margin-right: .8rem;
  height: 25px;
}

.header--fixed ul#menu-main-manu a {
  color: #339a99;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
}

.header--fixed .header__calltoUs .svg img {
  width: 20px;
  height: 20px;
}

.header--fixed .header__calltoUs a {
  color: #339a99;
  font-size: 22px;
  margin-left: .4rem;
}

.header--fixed .navbar {
  padding-top: 0;
  padding-bottom: 0;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  max-width: 1122px;
}

@media (max-width: 768px) {
  .header--fixed .navbar {
    margin: 0 auto;
    padding: 10px 10% 0;
  }
}

.header--fixed .dropdown-menu {
  margin-top: 0 !important;
}

.Kolposkopia {
  margin-top: 0rem;
  margin-bottom: 0;
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.Kolposkopia__title {
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}

.Kolposkopia__text {
  text-align: center;
  font-size: 21px;
  font-weight: 300;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #fff;
}

.Kolposkopia__button {
  text-align: center;
  margin: 1rem auto;
}

.Kolposkopia__button button {
  font-size: 16px;
  font-weight: 300;
  background: #fff;
  border: 3px solid #F5AFC4;
  border-radius: 11px;
  padding: .3rem 1.4rem;
}

.Kolposkopia__button button:hover {
  background: #F5AFC4;
  border: 3px solid #fff;
  text-decoration: none;
}

.Kolposkopia__button button:hover a {
  color: #fff;
}

.Kolposkopia__button button a {
  text-decoration: none;
}

.Kolposkopia__button button a:hover {
  text-decoration: none;
  color: #fff;
}

.pageKolposkopia__text {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pageKolposkopia__text__title {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.pageKolposkopia__text__text {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

.pageKolposkopia__text__text p {
  margin-bottom: 1rem;
}

.pageKolposkopia__text__text h2, .pageKolposkopia__text__text h3 {
  font-size: 20px;
  font-weight: 300;
}

.pageKolposkopia__text__text h4 {
  font-size: 18px;
}

.pageKolposkopia__parallax {
  /* Set a specific height */
  min-height: 300px;
  color: #fff;
  text-align: center;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.pageKontakt iframe {
  width: 100%;
  height: 400px;
}

@media (max-width: 768px) {
  .pageKontakt iframe {
    max-height: 60vh;
  }
}

.pageKontakt__text {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.pageKontakt__text__title {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.pageKontakt__text__text {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

.pageKontakt__text__text p {
  margin-bottom: 1rem;
}

.pageKontakt__text__text h2, .pageKontakt__text__text h3 {
  font-size: 20px;
  font-weight: 300;
}

.pageKontakt__text__text h4 {
  font-size: 18px;
}

.pageKontakt__3panels {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 4px solid #F5AFC4;
  border-bottom: 4px solid #F5AFC4;
}

.pageKontakt__3panels__icon {
  text-align: center;
}

.pageKontakt__3panels__icon img {
  text-align: center;
  height: 41px;
  width: auto;
  margin-bottom: 10px;
}

@media (max-width: 1280px) {
  .pageKontakt__3panels__icon img {
    height: 35px;
    max-width: 25px;
  }
}

.pageKontakt__3panels__text {
  font-size: 18px;
  text-align: center;
  font-weight: 300;
}

.pageKontakt__3panels__text h2, .pageKontakt__3panels__text h3, .pageKontakt__3panels__text h4 {
  font-size: 18px;
}

.pageKontakt__3panels .col-12 {
  margin-bottom: 2rem;
}

.MainSlider {
  margin-top: 0rem;
  margin-bottom: 3rem;
  text-align: center;
  background-color: transparent linear-gradient(90deg, #F5AFC4 0%, transparent 100%) 0% 0% no-repeat padding-box;
  background-image: url(http://localhost/lukoshek/wp-content/uploads/2022/04/kwiatki-01.png), -moz-linear-gradient(to right, #F5AFC4 0%, #fff 100%);
  background-repeat: no-repeat;
  background-position: right;
}

.MainSlider__row {
  align-items: center;
  padding: 2rem 0;
}

.MainSlider__row__img img {
  max-height: 400px;
  width: auto;
  padding: 2rem 0;
}

.MainSlider__tytul {
  font-family: "museo500";
  text-align: left;
  font-size: 44px;
  font-weight: 500;
}

.MainSlider__text {
  text-align: left;
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.MainSlider__text h4 {
  font-size: 20px;
  font-family: "museo300";
}

.MainSlider__button {
  text-align: left;
}

@media (max-width: 1280px) {
  .MainSlider__button {
    text-align: center;
  }
}

.MainSlider__button button {
  font-size: 16px;
  font-weight: 300;
  background: #fff;
  border: 3px solid #F5AFC4;
  border-radius: 11px;
  padding: .3rem 1.4rem;
}

.MainSlider__button button:hover {
  background: #F5AFC4;
  border: 3px solid #fff;
  text-decoration: none;
}

.MainSlider__button button:hover a {
  color: #fff;
}

.MainSlider__button button a {
  text-decoration: none;
}

.MainSlider__button button a:hover {
  text-decoration: none;
  color: #fff;
}

.map iframe {
  width: 100%;
  height: 400px;
}

@media (max-width: 768px) {
  .map iframe {
    max-height: 60vh;
  }
}

.map__text {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .map__text__title {
    margin-bottom: 1rem;
  }
}

.map__text__title h3 {
  font-size: 30px;
  font-weight: 300;
}

.map__text__text {
  text-align: center;
  font-size: 20px;
  font-weight: 300;
}

.Menu {
  background-image: url("../images/tlo-powtarzalne.png");
  background-repeat: repeat;
}

.Menu .container {
  margin-bottom: 5rem;
}

.Menu__title {
  margin: 5rem 0 2rem;
  border-bottom: 1px solid #AC3C07;
  opacity: 1;
}

.Menu__title__Title {
  font-size: 50px;
  font-weight: 800;
  color: #890B01;
}

.Menu__dishes__name {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.Menu__prices__row {
  margin-bottom: 1rem;
}

.Menu__prices__Name {
  font-size: 20px;
  font-weight: 500;
  text-transform: uppercase;
}

.Menu__prices__Products {
  font-size: 20px;
  font-weight: 300;
  text-transform: lowercase;
}

.Menu__prices__Price {
  font-size: 20px;
  font-weight: 800;
}

.Menu .nav-pills .nav-link.active, .Menu .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #AC3C07;
}

.Menu .nav-pills .nav-link {
  border-radius: 0;
}

.MenuMobile {
  background-image: url("../images/tlo-powtarzalne.png");
  background-repeat: repeat;
}

.MenuMobile .container {
  margin-bottom: 5rem;
}

.MenuMobile__title {
  margin: 5rem 0 2rem;
  border-bottom: 1px solid #AC3C07;
  opacity: 1;
}

.MenuMobile__title__Title {
  font-size: 40px;
  font-weight: 800;
  color: #890B01;
}

.MenuMobile__dishes [aria-expanded="true"] {
  color: #fff !important;
  background-color: #ac3c07;
}

.MenuMobile__dishes__name {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #890B01;
  background-color: none;
  padding: .5rem;
}

.MenuMobile__prices__row {
  margin-bottom: 1rem;
  display: flex;
}

.MenuMobile__prices__row > div {
  padding: 0;
}

.MenuMobile__prices__Name {
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
}

.MenuMobile__prices__Products {
  color: #464749;
  font-size: 18px;
  font-weight: 300;
  text-transform: lowercase;
}

.MenuMobile__prices__Price {
  font-size: 18px;
  font-weight: 800;
  text-align: right;
}

.MenuMobile .nav-pills .nav-link.active, .MenuMobile .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #AC3C07;
}

.MenuMobile .nav-pills .nav-link {
  border-radius: 0;
}

.navbar-collapse {
  padding: 10px 0;
}

@media (max-width: 1280px) {
  .navbar-collapse {
    padding: 0;
  }
  .navbar-collapse ul li {
    text-align: left;
  }
}

@media (min-width: 1281px) {
  .navbar-collapse .nav-item {
    padding: 0 10px;
  }
  .navbar-collapse .nav-item::after {
    content: '.';
    position: relative;
    left: 10px;
    top: -5px;
    font-size: 1rem;
  }
  .navbar-collapse .nav-item:nth-child(5) {
    padding: 0 0 0 10px;
    margin-right: 20px;
  }
  .navbar-collapse .nav-item:nth-child(5)::after {
    content: '';
  }
}

.navbar-collapse .dropdown:hover {
  -webkit-animation-name: unset;
  animation-name: unset;
  -webkit-animation-duration: unset;
  animation-duration: unset;
  -webkit-animation-timing-function: unset;
  animation-timing-function: unset;
  -webkit-animation-iteration-count: unset;
  animation-iteration-count: unset;
}

@media (min-width: 992px) {
  .navbar-collapse .dropdown:hover .dropdown-menu {
    display: block !important;
  }
}

.navbar-collapse .dropdown .dropdown-menu {
  background-color: transparent;
  border: 0;
  min-width: 250px;
  margin-top: -5px;
  display: none;
  background-color: rgba(255, 255, 255, 0.86);
}

@media (max-width: 1280px) {
  .navbar-collapse .dropdown .dropdown-menu {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1281px) {
  .navbar-collapse .dropdown .dropdown-menu {
    box-shadow: 3px 3px 3px #A5A5A5BF;
  }
}

@media (max-width: 992px) {
  .navbar-collapse .dropdown .dropdown-menu {
    padding-top: 0;
    padding-left: 7px;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link {
  transition: .2s;
}

@media (max-width: 1280px) {
  .navbar-collapse .dropdown .dropdown-menu .nav-link {
    padding: 11px 7px 9px 20px;
  }
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade {
  border-bottom: 1px solid transparent;
}

.navbar-collapse .dropdown .dropdown-menu .nav-link.hvr-border-fade:hover {
  border-bottom: 1px solid;
  border-bottom: 1px solid #F5AFC4;
  box-shadow: none !important;
}

.Oferta {
  margin-top: 0rem;
  margin-bottom: 0;
  text-align: center;
}

@media (min-width: 769px) {
  .Oferta {
    margin-bottom: 3rem;
  }
}

.Oferta__title {
  text-align: center;
  font-size: 30px;
  font-weight: 300;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (min-width: 769px) {
  .Oferta__title {
    margin-top: 3rem;
  }
}

.Oferta__text {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.Oferta__text p {
  font-size: 20px;
  font-weight: 300;
}

@media (max-width: 768px) {
  .Oferta__3panels {
    margin-bottom: 3rem;
  }
}

.Oferta__3panels__icon {
  text-align: center;
  margin-bottom: 1rem;
}

.Oferta__3panels__icon img {
  height: 95px;
  width: auto;
}

.Oferta__3panels__tytul {
  text-align: center;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 300;
}

.Oferta__3panels__tytul span {
  display: block;
  margin: 1rem auto;
  width: 43px;
  height: 3px;
  border-top: 3px solid #F5AFC4;
  opacity: 1;
}

.Oferta__3panels__text {
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
  min-height: 100px;
}

.Oferta__3panels__text p {
  font-size: 16px;
  font-weight: 300;
}

.Oferta__3panels__button {
  text-align: center;
  margin: 1rem auto;
}

.Oferta__3panels__button button {
  font-size: 16px;
  font-weight: 300;
  background: #fff;
  border: 3px solid #F5AFC4;
  border-radius: 11px;
  padding: .3rem 1.4rem;
}

.Oferta__3panels__button button:hover {
  background: #F5AFC4;
  border: 3px solid #fff;
  text-decoration: none;
}

.Oferta__3panels__button button:hover a {
  color: #fff;
}

.Oferta__3panels__button button a {
  text-decoration: none;
}

.Oferta__3panels__button button a:hover {
  text-decoration: none;
  color: #fff;
}

.pageOferta {
  padding-top: 3rem;
  margin-bottom: 2rem;
}

.pageOferta__titlePage {
  margin-bottom: 2rem;
  text-align: center;
  text-transform: uppercase;
}

@media (max-width: 1280px) {
  .pageOferta__content {
    padding: 0;
  }
}

.pageOmnie {
  padding-top: 3rem;
  text-align: center;
}

.pageOmnie__row {
  align-items: center;
}

.pageOmnie__row__img img {
  max-height: 400px;
  width: auto;
  padding: 2rem 0;
}

@media (max-width: 1280px) {
  .pageOmnie__row__content {
    padding: 0;
  }
}

.pageOmnie__tytul {
  text-align: left;
  font-family: "museo500";
  font-weight: 500;
}

.pageOmnie__text {
  text-align: left;
  font-size: 18px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.pageOmnie__text h2 {
  font-size: 20px;
  margin-bottom: 1rem;
  font-family: "museo500";
  font-weight: 500;
}

.pageOmnie__text h4 {
  font-size: 20px;
  font-family: "museo500";
  font-weight: 500;
}

.pageOmnie__secondText {
  background-color: transparent linear-gradient(90deg, #F5AFC4 0%, transparent 100%) 0% 0% no-repeat padding-box;
  background-image: url(http://localhost/lukoshek/wp-content/uploads/2022/04/kwiatki-01.png), -moz-linear-gradient(to right, #F5AFC4 0%, #fff 100%);
  background-repeat: no-repeat;
  background-position: right;
  text-align: left;
  padding: 3rem 0;
}

.pageOmnie__secondText li {
  margin: 1.5rem 0;
}

.pageOmnie__certyficate {
  background-color: #DADBD9;
  padding: 3rem 0;
  margin: 2px 0 3rem 0;
}

.pageOmnie__certyficate .row {
  align-items: center;
  text-align: left;
}

.pageOmnie__certyficate__img {
  text-align: center;
}

@media (max-width: 1280px) {
  .pageOmnie__certyficate__img {
    margin-bottom: 2rem;
  }
}

@media (max-width: 1280px) {
  .pageOmnie__certyficate__text {
    padding: 0;
  }
}

section {
  padding-top: 0;
  padding-bottom: 0;
  overflow: hidden;
  margin: 0;
}

@media (max-width: 480px) {
  section {
    padding-top: 0;
    padding-bottom: 0;
  }
}
