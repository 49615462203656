.Kolposkopia{
    margin-top: 0rem;
    margin-bottom: 0;
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 5rem;
    &__title{        
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #fff;
        h2{
        }
    }
    &__text{        
        text-align: center;
        font-size: 21px;
        font-weight: 300;
        margin-top: 1rem;
        margin-bottom: 1rem;
        color: #fff;
    }
    &__button{        
        text-align: center;
        margin: 1rem auto;
        button{
            @include ButtonWhite;
        }
    }
}

.pageKolposkopia{
    &__text{
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
        &__title {
            font-size: 30px;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
        &__text{
            text-align: center;
            font-size: 20px;
            font-weight: 300;
            p{
                margin-bottom: 1rem;
            }
            h2, h3{
                font-size: 20px;
                font-weight: 300;
            }
            h4{
                font-size: 18px;
            }
        }
    }
    &__parallax{
        /* Set a specific height */
        min-height: 300px;
        color: #fff;
        text-align: center;
      
        /* Create the parallax scrolling effect */
        background-attachment: fixed;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        h2 {
        }
    }
}