@font-face {
    font-family: 'museo_sans_500regular';
    src: url('../fonts/MuseoSans_500-webfont.eot');
    src: url('../fonts/MuseoSans_500-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/MuseoSans_500-webfont.woff') format('woff'),
         url('../fonts/MuseoSans_500-webfont.ttf') format('truetype'),
         url('../fonts/MuseoSans_500-webfont.svg#museo_sans_500regular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'museo500';
    src: url('../fonts/museo500-regular-webfont.eot');
    src: url('../fonts/museo500-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo500-regular-webfont.woff') format('woff'),
         url('../fonts/museo500-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'museo300';
    src: url('../fonts/museo300-regular-webfont.eot');
    src: url('../fonts/museo300-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/museo300-regular-webfont.woff') format('woff'),
         url('../fonts/museo300-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}