.pageKontakt{
    iframe{
        width:100%;
        height: 400px;
        @include rwd(tablet) {
            max-height: 60vh;
        }
    }
    &__text{
        text-align: center;
        margin-top: 3rem;
        margin-bottom: 3rem;
        &__title {
            font-size: 30px;
            font-weight: 300;
            text-transform: uppercase;
            margin-bottom: 1rem;
        }
        &__text{
            text-align: center;
            font-size: 20px;
            font-weight: 300;
            p{
                margin-bottom: 1rem;
            }
            h2, h3{
                font-size: 20px;
                font-weight: 300;
            }
            h4{
                font-size: 18px;
            }
        }
    }
    &__3panels{       
        padding-top: 2rem;
        padding-bottom: 2rem;
        border-top: 4px solid #F5AFC4;  
        border-bottom: 4px solid #F5AFC4;  
        &__icon {
            text-align: center;
            img{
                text-align: center;
                height: 41px;
                @include rwd (laptop){
                    height: 35px;
                    max-width: 25px;
                }
                width: auto;
                margin-bottom: 10px;
            }
        }
        &__text{
            font-size: 18px;
            text-align: center;
            font-weight: 300;
            h2, h3, h4{
                font-size: 18px;
            }
        }
        .col-12 {
            margin-bottom: 2rem
        }
    }
}