.footer{
    background-color: $cBackgroundFooter;
    padding-top: 1rem;
    padding-bottom: 1rem;
    &__icon {
        text-align: center;
        img{
            text-align: center;
            height: 50px;
            @include rwd (laptop){
                height: 30px;
            }
            width: auto;
        }
    }
    &__text{
        font-size: 18px;
        text-align: center;
        color: $cFooter;
        font-weight: 300;
        a{
            color: #fff;
        }
    }
    .col-12 {
        margin-bottom: 1rem;
    }
}

.AfterFooter{
    background-color: #fff;
    &__copyBox {
        color: $cAfterFooter;
        padding: 10px 0;
        font-size: 12px;
        font-weight: 300;
        @include rwd(tablet) {
            font-size: 10px;
        }
        &__Model{
            text-align: left;
            @include rwd(small-tablet) {
                text-align: center;
            }
        }
        &__Anronet{
            text-align: right;
            @include rwd(small-tablet) {
                text-align: center;
            }
        }
    }
}