body{
    color: $cBody;
    font-family: $fFirst;
    font-size:16px;
    font-weight: 300;
}
.mainContainer{
    max-width: 1410px;
    margin:0 auto;

    @include rwd(tablet){
        padding-left: 15px;
        padding-right: 15px;
    }
    @include rwd(tablet,true) {
        padding-right: unset;
        padding-left: unset;
    }
}
.container-fluid {
    width: 100%;
    @include rwd(large-tablet,true) {
        padding-right: unset;
        padding-left: unset;
    }
    @include rwd(tablet) {
        padding-right: unset;
        padding-left: unset;
    }
}

a{
    color: $cBody;
    &:hover {
        color: #F5AFC4;
    }
}

/* Pulse */
@-webkit-keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
  @keyframes hvr-pulse {
    25% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    75% {
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
    }
  }
/* BACKGROUND */



/* COLORS */


/*  others */


p{
    font-size:16px;
    font-weight: 300;
    @media(min-width:768px){
    }
    @media(min-width:1200px){
    }
}
.aLeft{
    text-align: left;
}
.aRight{
    text-align: right;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
    background: #890b01;
}
.slide{
    width:100%;
    height:100%;
    position:relative;
}

.psuedo-background-img{
    @include rwd(large-phone) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.novisible{
    display: none!important;
}
.showvisible{
    display: block!important;
    padding: 0 !important;
}

.centerOfScreen{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); /* Yep! */
        width: 48%;
        height: 59%;
}
h1,h2,h3,h4,h5{
    font-family: $fFirst;
}
h1{
    font-size: 30px;
}
.mainContainer ul,
.mainContainer ol{
    list-style: disc;
    margin-left: 1rem;
    @include rwd(tablet){
        margin-left: 2rem;
        margin-right: 2rem;
    }
}
#SectionBG{
    background-image: url(),
    -moz-linear-gradient(to right, #F5AFC4 0%, #fff 100%);
    background-repeat: no-repeat;
    background-position: right;
    height: 4px;
    margin: 0;
    padding:0;
}